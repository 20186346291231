<template>
  <div class="user">
    <div class="g-layout">
      <div class="g-layout-l" style="margin-left: 0">
        <div class="g-layout-l-nav">
          <div class="m-card">
            <div class="user-nav">
              <h3>{{ $t("lang.Your_account") }}</h3>
              <p @click="goLink(item.link)" v-for="(item, index) in linkArr1" :key="'linkArr1' + index" :class="{ active: $route.path.indexOf(item.link) !== -1 }">
                {{ item.title }}
              </p>
            </div>
            <div class="user-nav">
              <h3>{{ $t("lang.Contact_us") }}</h3>
              <p @click="goLink(item.link)" v-for="(item, index) in linkArr2" :key="'linkArr2' + index" :class="{ active: $route.path.indexOf(item.link) !== -1 }">
                {{ item.title }}
              </p>
            </div>
            <div class="user-nav">
              <h3>{{ $t("lang.Account_Settings") }}</h3>
              <p @click="goLink(item.link)" v-for="(item, index) in linkArr3" :key="'linkArr3' + index" :class="{ active: $route.path.indexOf(item.link) !== -1 }">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="g-layout-r">
        <router-view />
      </div>
    </div>
    <!-- <gSide></gSide> -->
    <talkWrap v-if="talkShow" :isUser="true"></talkWrap>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import gSide from "@components/gSide/gSide.vue";
import talkWrap from "@components/chat-wrap/index.vue";

export default {
  name: "user",
  components: { gSide, talkWrap },
  data() {
    return {
      talkShow: false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      exhibitionConfig: "getExhibitionConfig",
    }),
    linkArr1() {
      let arr = [
        { title: this.$t("lang.Home"), link: "/user/index" },
        { title: "Chat", link: "talk" },
        // { title: this.$t("lang.Ticket"), link: "/user/card" },
        { title: this.$t("lang.Business"), link: "/user/order" },
        { title: this.$t("lang.Inquiry_records"), link: "/user/inquiry" },
        { title: this.$t("lang.Supply_Demand"), link: "/user/need" },
        { title: "Activity", link: "/user/act" },
        { title: this.$t("lang.Following"), link: "/user/follow" },
        { title: "Notification", link: "/user/msg" },
      ];
      if (!this.enabledSupplyDemand) {
        arr.forEach((c, i) => {
          if (c.link === "/user/need") {
            arr.splice(i, 1);
          }
        });
      }
      return arr;
    },
    linkArr2() {
      return [
        { title: "Organzier", link: "/user/customer" },
        // { title: this.$t("lang.Feedback"), link: "/user/idea" },
      ];
    },
    linkArr3() {
      return [
        { title: this.$t("lang.Profile"), link: "/user/info" },
        { title: this.$t("lang.Bind_other_accounts"), link: "/user/bindAuth" },
        { title: this.$t("lang.Setting"), link: "/user/password/user" },
      ];
    },
    enabledSupplyDemand() {
      return this.exhibitionConfig && this.exhibitionConfig.feature && this.exhibitionConfig.feature.enabledSupplyDemand;
    },
  },
  created() {
    console.log(this.path);
  },
  mounted() {
    this.$Bus.$off("talkClose");
    this.$Bus.$on("talkClose", () => {
      this.talkShow = false;
    });
  },
  methods: {
    ...mapActions({}),
    goLink(link) {
      if (link == "talk") {
        this.talkShow = true;
        return;
      }
      if (link == "/user/order" || link == "/user/inquiry" || link == "/user/need") {
        if (this.userInfo && this.userInfo.inMember.isBlacklist) {
          this.$Message.warning("You do not have access");
          return;
        }
      }
      this.$router.push(`${link}?exhibitionId=${this.exhibitionId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.user {
  width: 1200px;
  margin: 0 auto;
}
.g-layout {
  &-l {
    display: flex;
    width: 170px;
    flex-shrink: 0;
  }
  &-l-nav {
    width: 100%;
  }
  .user-nav {
    padding: 20px 10px 20px 20px;
    border-bottom: 1px solid #f7f7f7;
    h3 {
      font-size: 16px;
      color: #333;
    }
    p {
      font-size: 14px;
      padding-top: 18px;
      cursor: pointer;
    }
    p.active,
    p:hover {
      @include font_color(#1890ff);
    }
  }
}
.user-info {
  padding: 40px;
  display: flex;
  &-l {
    display: flex;
    flex: 1;
    &-left {
      margin-right: 14px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: block;
      }
      .btn {
        margin: 16px 6px;
        background-color: #f7f7f7;
      }
    }
    &-right {
      h3 {
        font-size: 16px;
        color: #333;
        font-weight: normal;
        margin-bottom: 4px;
        span {
          margin-left: 8px;
          font-size: 12px;
          color: #666;
        }
      }
      p {
        font-size: 12px;
        color: #999;
        margin-bottom: 4px;
      }
    }
  }
  &-r {
    width: 150px;
    .card {
      width: 140px;
      height: 120px;
      background-color: #fff;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
    }
  }
}
</style>
